import { getLocaleFromPath } from '@/helpers/locale'

/**
 * Handles path prefix validation.
 *
 * This handles cases where the path prefix is in an unexpected format,
 * e.g. "/de-ch". If this is the case we redirect to a prefix with the
 * expected format, where the langcode is lowercase and the country is
 * uppercase.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  // Enforce no trailing slashes.
  if (to.path.endsWith('/') && to.path !== '/') {
    return navigateTo({ path: to.path.slice(0, -1), query: to.query })
  }

  const parts = getLocaleFromPath(to.path)

  // Invalid URL. We can't further navigate in SPA mode because we only
  // support paths that have a valid prefix. By triggering a navigation
  // using a full page load we let platform, varnish, Drupal or Nuxt SSR
  // handle redirecting to a valid path with a prefix or return a 404.
  if (!parts.country || !parts.langcode) {
    // On the server, if we end up here, the path is definitely invalid
    // and we can just throw a 404.
    // Also if we already vistied the page, so we avoid an endless loop.
    if (process.server || to.path === from.path) {
      if (to.path === '/') {
        // this mostly happens when you curl localhost within the frontend container.
        return navigateTo(
          { path: '/en-US', query: to.query },
          { redirectCode: 307 },
        )
      }

      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
    }
    // Force page load client side.
    return navigateTo(
      { path: to.path, query: to.query },
      { open: { target: '_self' } },
    )
  }

  // Check if the previous and the next route have the same prefix.
  // If they don't, that means the country and/or language have changed.
  // We force a full page load so that the state is correctly translated.
  // That way we also prevent unnecessary queries: If the user is on
  // /de-CH/foobar and clicks on a link to en-US, the route query would make a
  // request to get /en-US/foobar, but would still send language and country
  // to be from before. In this case Drupal would return a redirect and we
  // would have to make the request a second time.
  const fromPrefix = from.params.prefix
  const toPrefix = to.params.prefix
  if (
    typeof fromPrefix === 'string' &&
    typeof toPrefix === 'string' &&
    fromPrefix !== toPrefix &&
    import.meta.client
  ) {
    // Force page load client side.
    return navigateTo(
      { path: to.path, query: to.query },
      { open: { target: '_self' } },
    )
  }

  const lowercaseLanguage = parts.langcode.toLowerCase()
  const uppercaseCountry = parts.country.toUpperCase()

  // The desired prefix in the format "/de-CH".
  const desiredPrefix = `/${lowercaseLanguage}-${uppercaseCountry}`

  // Target path does not have our desired prefix.
  if (!to.path.startsWith(desiredPrefix)) {
    // Replace the wrong prefix with the desired prefix.
    const destination = to.path.replace(
      `/${parts.langcode}-${parts.country}`,
      desiredPrefix,
    )

    // Redirect to the same path with the correct prefix.
    return navigateTo(
      { path: destination, query: to.query },
      {
        redirectCode: 302,
        replace: true,
      },
    )
  }

  // All good.
})
